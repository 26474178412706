import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_vertical_all_center, fill_vertical_cross_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import { LogoCard } from '~/pages/a2330/component/LogoCard';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import IntradayTrendChart from '~/modules/trendChart2';
import { ScreenerContent } from '~/pages/a2330/component/ScreenerContent';
// 多方
// 1. 今日高點有突破30日最高價
// 2. 單日三大法人買超
// 3. 月營收年增率為正
// 空方
// 1. 今日低點有突破30日最低價
// 2. 單日三大法人賣超
// 3. 月營收年增率為負
export const A2330_SidePane = memo(function A2330_SidePane() {
    const charting = useSnapshot(store).charting;
    return (<styleds.container>
      <styleds.logoContent>
        <LogoCard />
      </styleds.logoContent>
      <styleds.symbol.container>
        <styleds.symbol.titleQuote>
          <TitleSymbolQuote.Simple symbol={charting.symbol}/>
        </styleds.symbol.titleQuote>

        <styleds.symbol.trendChart>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-14} priceTicksSize={12}/>
        </styleds.symbol.trendChart>
      </styleds.symbol.container>
      <ScreenerContent />
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    width: calc(100% - 6px);
    padding: 8px;
    gap: 4px;
    border-radius: 4px;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 0 4px;
  `,
    logoContent: styled.div `
    ${fill_vertical_all_center};
    height: 100px;
  `,
    symbol: {
        container: styled.div `
      ${fill_vertical_cross_center};
      width: 100%;
      height: 178px;
      padding: 4px;
      border: 1px solid #acacac;
      border-radius: 4px;
    `,
        titleQuote: styled.div `
      ${fill_horizontal_cross_center};
      width: 100%;
      height: 20px;
    `,
        trendChart: styled.div `
      width: 100%;
      height: 150px;
      border-radius: 4px;
    `,
    },
};
