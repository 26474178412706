import { store } from '../heineken_template/_private/store';
import { defaults_volume } from '~/trades/indicators/defaults/defaults_volume';
import { a2330_bandTrade } from '~/trades/indicators/a2330/a2330_bandTrade';
const DEFAULT_SYMBOL = '2330';
const a2330_Indicators = [a2330_bandTrade, defaults_volume];
export const a2330_strategyGroup = {
    main: [
        {
            displayName: 'a2330',
            indicators: [...a2330_Indicators],
            symbol: DEFAULT_SYMBOL,
            interval: 'D',
            panesRatio: 60,
        },
    ],
};
export const a2330_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...a2330_Indicators];
    store.charting.initStrategy({
        configs: [...a2330_strategyGroup.main],
    });
    store.charting.changeSymbol(DEFAULT_SYMBOL);
};
