import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Pagination } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { StockSelectionBoard } from '~/pages/a2330/component/StockSelectionBoard';
import { a2330_store } from '~/pages/a2330/a2330_store';
import { SymbolSearch } from '~/pages/a2330/component/SymbolSearch';
import { SimpleQuoteList } from '~/pages/a2330/component/SimpleQuoteList';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { useMount } from 'react-use';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSortSignalrDataStore, useSortSignalrDataStore2, } from '~/modules/symbolQuote/simple/useSortStore';
import { store } from '~/pages/heineken_template/_private/store';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const ScreenerContent = memo(function ScreenerContent() {
    const state = useSnapshot(a2330_store);
    const me = useSnapshot(fr_me);
    const [proUser, setProUser] = useState(false);
    useEffect(() => {
        const tutorialProduct = me?._ourMe?.subscriptions.find(s => s.agentName === 'a2330' && s.productName === 'web_stock_pro');
        if (tutorialProduct && !tutorialProduct.isExpired) {
            setProUser(true);
        }
        else {
            setProUser(false);
        }
    }, [me]);
    //Pagination--
    const [activePage, setPage] = useState(1);
    const uniqueData = Array.from(new Set(state.stockSymbol));
    const maxItemsPerPage = 10;
    const longFirebase = useFirebaseValue('a2330_signal', 'long')?.symbols ?? [];
    const shortFirebase = useFirebaseValue('a2330_signal', 'short')?.symbols ?? [];
    let firebaseSymbol = state.stockSelectionType === 'long' ? longFirebase : shortFirebase;
    if (!proUser)
        firebaseSymbol = [];
    const firebaseSymbolNumber = firebaseSymbol.length;
    const remindingSymbolNumber = 30 - firebaseSymbolNumber;
    /** 更換條件時 將起始值設為1 */
    useEffect(() => {
        setPage(1);
    }, [JSON.stringify(state.stockSelectionCondition)]);
    useEffect(() => {
        signalrStore2.addQuote(uniqueData);
        signalrStore2.addQuote(firebaseSymbol);
        return () => {
            signalrStore2.removeQuote(uniqueData);
            signalrStore2.removeQuote(firebaseSymbol);
        };
    }, [JSON.stringify(uniqueData)]);
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'changePrecent';
        useSortSignalrDataStore2.sortKey = 'changePrecent';
    });
    //取得排序後的資料
    const sortedFirebaseSymbol = useSignalr2QuoteSort(firebaseSymbol, 2) ?? [];
    const sortedData = useSignalr2QuoteSort(uniqueData, 1) ?? [];
    //分離與FirebaseSymbol沒有共同的Symbol（避免重複）
    const uncommonSymbols = sortedData.filter(symbol => !firebaseSymbol.includes(symbol));
    //如果為空方策略，則由跌幅高至低排列
    if (state.stockSelectionType !== 'long') {
        uncommonSymbols.reverse();
        sortedFirebaseSymbol.reverse();
    }
    // 依照FirebaseSymbol數量，取得最多30檔標的
    const topSymbols = uncommonSymbols.slice(0, remindingSymbolNumber);
    // 所有的symbol組合在一起
    const top30Symbols = sortedFirebaseSymbol.concat(topSymbols);
    /** 起始 */
    const startIndex = (activePage - 1) * maxItemsPerPage;
    /** 終止 */
    const endIndex = startIndex + maxItemsPerPage;
    /** 總頁數 */
    const totalPage = Math.ceil(top30Symbols.length / maxItemsPerPage);
    /** 對應頁數返回的商品列 */
    const slicedItems = top30Symbols.slice(startIndex, endIndex);
    // 更新搜尋結果
    const mutableArray = [...state.searchedSymbol];
    const symbolsInfo = signalrHooks2.useQuotes(state.searchedSymbol.length === 0 ? slicedItems : mutableArray);
    return (<div css={css `
        ${fill_vertical_cross_center};
        height: calc(100% - 290px);
        padding: 8px;
        border: 1px solid #acacac;
        border-radius: 4px;
      `}>
      <styleds.searchTitle>AI型態學精選個股</styleds.searchTitle>
      <StockSelectionBoard />
      <styleds.resultContent>
        搜尋結果: {state.stockSelectionCondition.length >= 2 ? top30Symbols.length : 0}檔標的
      </styleds.resultContent>
      <styleds.symbolListContent>
        {state.stockSelectionCondition.length >= 2 ? (<div css={css `
              width: 100%;
              height: 100%;
            `}>
            <styleds.searchContent>
              <SymbolSearch symbols={top30Symbols}/>
            </styleds.searchContent>
            <styleds.symbolList>
              <SimpleQuoteList quoteValue={symbolsInfo} chart={store.charting}/>
            </styleds.symbolList>
          </div>) : (<div>請選擇兩種以上條件</div>)}
      </styleds.symbolListContent>
      <styleds.paginationContent>
        <Pagination total={totalPage === 0 ? 1 : totalPage} siblings={0} 
    // 選到非第一頁後更新條件，標的更新，但頁碼仍顯示在原本的頁碼
    value={activePage} onChange={val => setPage(val)}/>
      </styleds.paginationContent>
    </div>);
});
const styleds = {
    searchTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    font-size: 24px;
    color: #ffffff;
    background-color: #868e96;

    border-radius: 4px;
    cursor: pointer;
  `,
    watchlistButton: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
  `,
    resultContent: styled.div `
    ${fill_horizontal_all_center};
    /* background-color: #25262a; */
    height: 26px;
    font-size: 12px;
    border-radius: 4px;
  `,
    searchContent: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    /* background-color: #252525; */
  `,
    symbolListContent: styled.div `
    ${fill_vertical_all_center};
    ${scrollbar2Css};
    width: 100%;
    height: calc(100% - 160px);
    padding-bottom: 8px;
  `,
    symbolList: styled.div `
    height: calc(100% - 40px);
  `,
    paginationContent: styled.div `
    ${fill_vertical_all_center};
    height: 30px;
  `,
};
