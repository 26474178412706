import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { asiajye_init } from '~/pages/asiajye/_private/asiajye_init';
import { asiajyeStock_init } from '~/pages/asiajye_stock/_private/asiajyeStock_init';
import { gd1788_init } from '~/pages/gd1788/_private/gd1788_init';
import { goldbrainStock_init } from '~/pages/goldbrain_stock/_private/goldbrainStock_init';
import { good178S_init } from '~/pages/good178_stock/_private/good178S_init';
import { hyt888Stock_init } from '~/pages/hyt888_stock/_private/hyt888Stock_init';
import { kubujuku_init } from '~/pages/kabujukuTW/_private/kubujuku_init';
import { lung88988_init } from '~/pages/lung88988/_private/lung88988_init';
import { ok1788Stock_init } from '~/pages/ok1788_stock/_private/ok1788Stock_init';
import { weng888Stock_init } from '~/pages/weng888_stock/_private/weng888Stock_init';
import { win168_init } from '~/pages/win168/_private/win168_init';
import { winnerA_init } from '~/pages/winnerA/_private/winnerA_init';
import { winnerB_init } from '~/pages/winnerB/_private/winnerB_init';
import { winnerC_init } from '../winnerC/_private/winnerC_init';
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps';
import { sungop_init } from '~/pages/sungop/_private/sungop_init';
import { sungopStock_init } from '~/pages/sungop_stock/_private/sungopStock_init';
import { lung88988Stock_init } from '~/pages/lung88988_stock/_private/lung88988Stock_init';
import { investment_init } from '~/pages/investment_demo/investment_init';
import { s178178_init } from '../s178178/s178178_init';
import { winner98_init } from '../winner98/_private/winner98_init';
import { winner98_fullVersion_init } from '../winner98_full_version/private_/winner98_fullVersion_init';
import { mvp888Stock_init } from '../mvp888_stock/_private/mvp888Stock_init';
import { m168168Stock_init } from '../m168168_stock/_private/m168168Stock_init';
import { virtualExchange_init } from '~/pages/virtual_exchange/virtualExchange_init';
import { strategyCenter_init } from '~/pages/strategy_center/strategy_center_init';
import { eurex_init } from '../eurex/_private/eurex_init';
import { big3_init } from '../big3/_private/big3_init';
import { big3Stock_init } from '../big3_stock/big3Stock_init';
import { good178_init } from '../good178/_private/good178_init';
import { imoney8888_init } from '../imoney8888/_private/imoney8888_init';
import { yaya168Stock_init } from '../yaya168_stock/yaya168Stock_init';
import { south_init } from '../south/south_init';
import { jerry597_init } from '../jerry597_stock/jerry597_init';
import { daddy960_goodway_init } from '../daddy960_goodway/daddy960_goodway_init';
import { god_init } from '../god/god_init';
import { opkevin_init } from '../daddy960_opkevin/opkevin_init';
import { gorich55688_init } from '../gorich55688_stock/gorich55688_init';
import { daddy960_stocknerve_init } from '../daddy960_stocknerve/daddy960_stocknerve_init';
import { winner98_crypto_init } from '../winner98_crypto/winner98_crypto_init';
import { goodpsy_init } from '../goodpsy/goodpsy_init';
import { stanli_init } from '../stanli/stanli_init';
import { daddy960_tc1688_init } from '../daddy960_tc1688/daddy960_tc1688_init';
import { daddy960_revive_init } from '../daddy960_revive/daddy960_revive_init';
import { datatree_init } from '../datatree/datatree_init';
import { rich888_init } from '../rich888_stock/rich888_init';
import { win988_init } from '../win988/win988_init';
import { wu5856_init } from '../wu5868_stock/wu5868_init';
import { fubon_init } from '../fubon/fubon_init';
import { pionex_init } from '../pionex/pionex_init';
import { sgx_init } from '../sgx/sgx_init';
import { asiajye_intl_init } from '../asiajye_intl/asiajye_intl_init';
import { promotion_init } from '../promotion/promotion_init';
import { boleStock_init } from '../bole_stock/_private/boleStock_init';
import { edwin_init } from '../edwin/edwin_init';
import { more888_init } from '../more888/more888_init';
import { more888Stock_init } from '../more888_stock/more888Stock_init';
import { freeman_init } from '../freeman/freeman_init';
import { a2330_init } from '~/pages/a2330/a2330_init';
const templateProps = new TemplateProps();
if (fr_agents.is['futuresai@web'])
    investment_init(templateProps);
if (fr_agents.is['sungop@web_stock'])
    sungopStock_init(templateProps);
if (fr_agents.is['sungop@web'])
    sungop_init.indexPage(templateProps);
if (fr_agents.is['daddy960@web_goodway'])
    daddy960_goodway_init.indexPage(templateProps);
if (fr_agents.is['asiajye@web'])
    asiajye_init(templateProps);
if (fr_agents.is['asiajye@web_os_futures'])
    asiajye_intl_init(templateProps);
if (fr_agents.is['asiajye@web_stock'])
    asiajyeStock_init(templateProps);
if (fr_agents.is['goldbrain@web_stock'])
    goldbrainStock_init(templateProps);
if (fr_agents.is['good178@web_stock'])
    good178S_init(templateProps);
if (fr_agents.is['good178@web'])
    good178_init(templateProps);
if (fr_agents.is['hyt888@web_stock'])
    hyt888Stock_init(templateProps);
if (fr_agents.is['kabujukuTW@web_stock'])
    kubujuku_init(templateProps);
if (fr_agents.is['lung889881688@web'])
    lung88988_init(templateProps);
if (fr_agents.is['lung889881688@web_stock'])
    lung88988Stock_init(templateProps);
if (fr_agents.is['gd1788@web'])
    gd1788_init(templateProps);
if (fr_agents.is['ok1788@web_stock'])
    ok1788Stock_init(templateProps);
if (fr_agents.is['weng888@web_stock'])
    weng888Stock_init(templateProps);
if (fr_agents.is['win168@web'])
    win168_init(templateProps);
if (fr_agents.is['winner@web_A'])
    winnerA_init(templateProps);
if (fr_agents.is['winner@web_B'])
    winnerB_init(templateProps);
if (fr_agents.is['winner@web_C'])
    winnerC_init(templateProps);
if (fr_agents.is['s178178@web'])
    s178178_init(templateProps);
if (fr_agents.is['winner98@web'])
    winner98_init(templateProps);
if (fr_agents.is['winner98@crypto'])
    winner98_crypto_init(templateProps);
if (fr_agents.is['mvp888@web_stock'])
    mvp888Stock_init(templateProps);
if (fr_agents.is['m168168@web_stock'])
    m168168Stock_init(templateProps);
if (fr_agents.is['winner98@backtest'])
    winner98_fullVersion_init(templateProps);
if (fr_agents.is['futuresai@exchange'])
    virtualExchange_init(templateProps);
if (fr_agents.is['futuresai@strategy_center'])
    strategyCenter_init(templateProps);
if (fr_agents.is['futuresai@promotion'])
    promotion_init.indexPage(templateProps);
if (fr_agents.is['eurex@web'])
    eurex_init(templateProps);
if (fr_agents.is['big3@web'])
    big3_init(templateProps);
if (fr_agents.is['big3@web_stock'])
    big3Stock_init(templateProps);
if (fr_agents.is['more888@web'])
    more888_init(templateProps);
if (fr_agents.is['more888@web_stock'])
    more888Stock_init(templateProps);
if (fr_agents.is['imoney8888@web_stock'])
    imoney8888_init(templateProps);
if (fr_agents.is['yaya168@web_stock'])
    yaya168Stock_init(templateProps);
if (fr_agents.is['south@web'])
    south_init(templateProps);
if (fr_agents.is['gorich55688@web_stock'])
    gorich55688_init(templateProps);
if (fr_agents.is['jerry597@web_stock'])
    jerry597_init(templateProps);
if (fr_agents.is['bole@web_stock'])
    boleStock_init.indexPage(templateProps);
if (fr_agents.is['god@web'])
    god_init(templateProps);
if (fr_agents.is['daddy960@web_opkevin'])
    opkevin_init(templateProps);
if (fr_agents.is['daddy960@web_stocknerve'])
    daddy960_stocknerve_init.indexPage(templateProps);
if (fr_agents.is['daddy960@web_tc1688'])
    daddy960_tc1688_init(templateProps);
if (fr_agents.is['daddy960@web_revive'])
    daddy960_revive_init(templateProps);
if (fr_agents.is['goodpsy@web'])
    goodpsy_init.indexPage(templateProps);
if (fr_agents.is['rich888@web_stock'])
    rich888_init(templateProps);
if (fr_agents.is['stanli@web'])
    stanli_init.indexPage(templateProps);
if (fr_agents.is['datatree@web'])
    datatree_init.indexPage(templateProps);
if (fr_agents.is['win988@web'])
    win988_init(templateProps);
if (fr_agents.is['wu5868@web_stock'])
    wu5856_init(templateProps);
if (fr_agents.is['fubon@web'])
    fubon_init(templateProps);
if (fr_agents.is['pionex@web'])
    pionex_init(templateProps);
if (fr_agents.is['sgx@web'])
    sgx_init(templateProps);
if (fr_agents.is['edwin@web'])
    edwin_init(templateProps);
if (fr_agents.is['freeman@web'])
    freeman_init(templateProps);
if (fr_agents.is['a2330@web_stock'])
    a2330_init(templateProps);
export default templateProps.NextPage;
