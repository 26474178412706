import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fr_me } from '../heineken_template/_fr/fr_me';
import { A2330_SidePane } from '~/pages/a2330/a2330_SidePane';
// import { daddy960_revive_initStyling } from './strong_initStyling'
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import { A2330_Topbar } from '~/pages/a2330/a2330_Topbar';
import { a2330_initStrategies } from '~/pages/a2330/a2330_initStrategies';
export const a2330_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web_stock',
        'web_stock_pro',
    ]);
    useThemeStore.setState({ theme: 'light' });
    store.charting.setThemeMode('light');
    a2330_initStrategies();
    templateProps.hooks.add(fr_me.useInstall);
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: '2330',
        interval: '1D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledPaneMenu: true,
        disabledTimeframesToolbar: false,
        disabledHeaderChartType: false,
        disabledHeaderSaveload: false,
        disabledHeaderCompare: false,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px calc(100vh - 48px);
    grid-template-columns: 336px 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Drawer1 Row1 Row1'
      'Drawer1 Chart Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px calc(100% - 56px);
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px calc(100% - 56px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    // Init components
    templateProps.layout.Row1 = <A2330_Topbar />;
    templateProps.layout.Drawer1 = <A2330_SidePane />;
    templateProps.hooks.add(useSignalrStart2_1);
};
